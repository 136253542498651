<template>
  <div>
    <div class="flex" v-if="showIncludeConversion">
      <span class="font-bold text-lg">Include Conversion &nbsp;</span>
      <vs-switch v-model="includeConversionSwitch" />
    </div>

    <vs-table
      search
      stripe
      border
      description
      :data="table.data"
      :max-items="table.data.length"
      :total="table.data.length"
    >
      <template slot="thead">
        <vs-th sort-key="">Valid From</vs-th>
        <vs-th sort-key="">Valid To</vs-th>
        <vs-th sort-key="">SKU Code</vs-th>
        <vs-th sort-key="">Item Name</vs-th>
        <vs-th sort-key="">Item Unit</vs-th>
        <vs-th sort-key="">Qty From</vs-th>
        <vs-th sort-key="">Qty To</vs-th>
        <vs-th sort-key="">Price</vs-th>
        <vs-th>Notes</vs-th>
        <vs-th>Markup</vs-th>
        <vs-th v-show="showEdit && exceptStatus != 'released'">Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td v-if="showEditMethod(!showEdit, tr)">{{
            dateFormat(tr.valid_from, "DD/MM/YYYY")
          }}</vs-td>
          <vs-td v-else>
            <vs-input
              v-model="tr.valid_from"
              type="date"
              :disabled="indextr !== 0"
              style="width: 100px"
              :min="headerValidFrom"
              :max="headerValidTo"
            />
          </vs-td>
          <vs-td v-if="showEditMethod(!showEdit, tr)">{{
            dateFormat(tr.valid_to, "DD/MM/YYYY")
          }}</vs-td>
          <vs-td v-else>
            <vs-input
              v-model="tr.valid_to"
              type="date"
              :disabled="indextr !== 0"
              style="width: 100px"
              :min="headerValidFrom"
              :max="headerValidTo"
            />
          </vs-td>
          <vs-td>{{ tr.sku_code }}</vs-td>
          <vs-td> {{ tr.item_name }} </vs-td>
          <vs-td> {{ tr.item_unit }} </vs-td>
          <vs-td v-if="showEditMethod(!showEdit, tr)">
            {{ tr.qty_from }}
          </vs-td>
          <vs-td v-else>
            <NumberInput v-model="tr.qty_from" />
          </vs-td>
          <vs-td v-if="showEditMethod(!showEdit, tr)"> {{ tr.qty_to }} </vs-td>
          <vs-td v-else>
            <NumberInput v-model="tr.qty_to" />
          </vs-td>
          <vs-td v-if="showEditMethod(!showEdit, tr)">
            Rp. {{ numberFormat(tr.price) }}
          </vs-td>
          <vs-td v-else>
            <NumberInput v-model="tr.price" />
          </vs-td>
          <vs-td v-if="showEditMethod(!showEdit, tr)"> {{ tr.notes }} </vs-td>
          <vs-td v-else>
            <vs-textarea
              v-model="tr.notes"
              style="width: 100%"
              rows="1"
              placeholder="Notes"
            />
          </vs-td>
          <vs-td v-if="showEditMethod(!showEdit, tr)"> {{ tr.markup }} </vs-td>
          <vs-td v-else>
            <NumberInput v-model="tr.markup" />
          </vs-td>
          <vs-td
            v-show="showEdit && exceptStatus != 'released' && !tr.is_conversion"
          >
            <vs-button
              @click="deleteItemRow(indextr)"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              size="small"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- <vs-pagination
      v-show="!showEdit"
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    /> -->
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import { currencyFormatIDR, numberFormat, dateFormat } from "@/utils/helper";
import * as XLSX from "xlsx";
import _ from "lodash";
import NumberInput from "@/components/master/NumberInput.vue";

const PricingManagementV3Repository = RepositoryFactory.get(
  "pricingManagementV3"
);

export default {
  components: { NumberInput },
  props: {
    filter: {
      type: Array,
      default: () => {
        return [];
      },
    },
    priceID: {
      type: Number,
      required: true,
      default: 0,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    exceptStatus: {
      type: String,
      default: "",
    },
    headerValidFrom: {
      type: String,
      default: null,
    },
    headerValidTo: {
      type: String,
      default: null,
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
      includeConversionSwitch: false,
      showIncludeConversion: true,
      header: {
        external_code: "",
        code: "",
        description: "",
        valid_from: "",
        valid_to: "",
        attachments: [],
        note: "",
        created_by: "",
        approved_by: "",
        create_date: "2024-06-20",
        approved_date: null,
        currency_id: 12,
        distribution_channel_ids: [],
        supplier_id: 0,
        sales_channel_ids: [],
        pricing_group_ids: [],
        territory_ids: [],
        file_price_item: "[object File]",
        status: false,
        calculation: "Final",
        customer_ids: [],
      },
    };
  },
  watch: {
    firstValidFrom(newVal) {
      this.table.data.forEach((item, index) => {
        if (index !== 0) item.valid_from = newVal;
      });
      this.updateHeader();
    },
    firstValidTo(newVal) {
      this.table.data.forEach((item, index) => {
        if (index !== 0) item.valid_to = newVal;
      });
      this.updateHeader();
    },
    includeConversionSwitch: {
      handler: function (val) {
        this.getData();
      },
    },
  },
  methods: {
    dateFormat,
    updateHeader() {
      this.form.valid_from = this.firstValidFrom;
      this.form.valid_to = this.firstValidTo;
    },
    getListPriceItemAsExcelFile() {
      let data = this.table.data;
      let newResults = [];
      for (let i = 0; i < data.length; i++) {
        if (!data[i].is_conversion) {
          let result = {
            "SKU Code": data[i].sku_code,
            Name: data[i].item_name,
            Unit: data[i].item_unit,
            From: data[i].qty_from,
            to: data[i].qty_to,
            Price: data[i].price,
            Notes: data[i].notes,
            Markup: data[i].markup,
            "Valid From": data[i].valid_from,
            "Valid To": data[i].valid_to,
          };
          newResults.push(result);
        }
      }

      if (this.$route.params.status == "released") {
        if (newResults.length == 0) {
          this.$vs.notify({
            title: "Warning",
            text: "No data edited, please edit data first",
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
      }

      const worksheet = XLSX.utils.json_to_sheet(newResults);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      var wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      var blob = new Blob([new Uint8Array(wbout)], {
        type: "application/octet-stream",
      });
      return blob;
    },
    deleteAll() {
      this.table.data = [];
    },
    deleteItemRow(index) {
      this.table.data.splice(index, 1);
    },
    setSelectedData(data) {
      let headerValidFrom, headerValidTo;
      if (this.form.valid_from != null && this.form.valid_to != null) {
        headerValidFrom = dateFormat(this.form.valid_from, "YYYY-MM-DD");
        headerValidTo = dateFormat(this.form.valid_to, "YYYY-MM-DD");
      }

      let validFrom, validTo;

      validFrom = headerValidFrom;
      validTo = headerValidTo;

      let response = {
        id: null,
        created_at: null,
        updated_at: null,
        price_id: null,
        sku_code: data.code,
        item_name: data.name,
        item_unit: data.unit,
        qty_from: data.qty_from || 0,
        qty_to: data.qty_to || 0,
        price: data.price || 0,
        discountable: 0,
        item_unit_id: data.id,
        markup: data.markup || 0,
        notes: data.notes || "",
        valid_from: validFrom || null,
        valid_to: validTo || null,
        is_conversion: data.is_conversion || false,
      };

      if (this.table.data == null) {
        this.table.data = [];
        this.table.data.push(response);
        return;
      }

      for (let i = 0; i < this.table.data.length; i++) {
        if (
          this.table.data[i].sku_code == response.sku_code &&
          this.table.data[i].item_unit == response.item_unit
        ) {
          this.$vs.notify({
            title: "Warning",
            text: "SKU Code and Unit already exist",
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
      }

      this.table.data.push(response);
    },
    numberFormat,
    currencyFormatIDR,
    tableDefaultState() {
      return {
        olds: [],
        data: [],
        length: 0,
        page: 0,
        search: "",
        order: "desc",
        sort: "id",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      if(this.priceID==null) {
        return true;
      }
      this.$vs.loading();
      try {
        const params = {
          params: {
            page: this.table.page,
            length: this.table.length,
            order: this.table.order,
            sort: this.table.sort,
            search: this.table.search,
            status: this.table.status,
            ...this.filter,
          },
        };
        let resp = null;
        let isAmend = this.$route.params.isAmend;
        let status = this.$route.params.status;

        resp = await PricingManagementV3Repository.getListPriceItemByPriceID(
          this.priceID,
          params
        );

        if (resp.code == 200) {
          this.table.data = [];
          if (resp.data.records != null) {
            resp.data.records.map((item) => {
              if (!this.includeConversionSwitch) {
                if (item.is_conversion) {
                  return;
                }
              }
              let validFrom = item.valid_from.split("/").reverse().join("-");
              let validTo = item.valid_to.split("/").reverse().join("-");

              let data = {
                id: item.id,
                price_id: item.price_id,
                sku_code: item.sku_code,
                item_name: item.item_name,
                item_unit: item.item_unit,
                qty_from: item.qty_from,
                qty_to: item.qty_to,
                price: item.price,
                discountable: item.discountable,
                item_unit_id: item.item_unit_id,
                markup: item.markup,
                notes: item.notes,
                valid_from: validFrom,
                valid_to: validTo,
                is_conversion: item.is_conversion,
              };
              this.table.data.push(data);
            });

            this.sortData();

            this.table.olds = _.cloneDeep(this.table.data);
          }
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while draft sales price",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    sortData() {
      this.table.data.sort((a, b) => {
        if (a.is_conversion) {
          return 1;
        }
        if (b.is_conversion) {
          return -1;
        }
        return a.sequence_import - b.sequence_import;
      });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    setFilter(data) {
      this.table.page = 1;
      this.filter = data;
      this.getData();
    },
    showEditMethod(showEdit, tr) {
      if (tr.is_conversion) {
        return true;
      }
      return showEdit;
    },
  },
  mounted() {
    if (
      this.$route.params.status == "amend" ||
      this.$route.params.status == "create"
    ) {
      this.showIncludeConversion = false;
    }

    setTimeout(() => {
      this.getData();
    }, 1000);
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.table.page = val;
        this.handleChangePage(val);
      },
    },
    firstValidFrom() {
      return this.table.data.length ? this.table.data[0].valid_from : null;
    },
    firstValidTo() {
      return this.table.data.length ? this.table.data[0].valid_to : null;
    },
  },
};
</script>
