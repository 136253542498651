<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisable"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.Code }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.Code }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: [],
    };
  },
  methods: {
    async setSelectedDataByIDs(ids) {
      await this.options.forEach((element) => {
        if (ids.includes(element.ID)) {
          this.selected.push(element);
        }
      });
    },
    setDefaultSelectedDataByCode(code) {
      this.selected = this.options.filter((element) => element.Code == code);
      return this.selected;
    },
    async getData() {
      try {
        const resp = await MasterRepository.getCurrencies();
        if (resp.code == 200) {
          var options = [];
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching currencies",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
